import { useIntl } from 'react-intl';
import useNotifications from '../components/NotificationPopup/useNotifications';

export const useNotificationOnApiError = () => {
  const { sendNotification } = useNotifications();
  const intl = useIntl();

  const validateResponse = (response) => {
    if (!response.ok) sendNotification(intl.formatMessage({ id: 'common.error.api' }));
    return response;
  };

  const networkErrorNotification = () => sendNotification(intl.formatMessage({ id: 'common.error.network' }));

  return { validateResponse, networkErrorNotification };
};
