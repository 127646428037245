import { useEffect } from 'react';
import { useState, useRef } from 'react';
import { getCategoriesRoutes, initCategoryRoutesFetcher, loadData } from 'Features/categories/categoryRoutesFetcher';
import { isCategoryRoute } from 'Features/categories/isCategoryRoute';

export const useCategoryRouteValidator = (isValidCategorySSR, pathname, SSRPath) => {
  const [isValidCategory, setIsValidCategory] = useState(isValidCategorySSR);
  const lastPathnameRef = useRef(SSRPath);
  const lastPathname = lastPathnameRef?.current;
  //todo: affects TTI
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (getCategoriesRoutes() === null) initCategoryRoutesFetcher();
  //   }, 4000);
  // }, []);

  useEffect(() => {
    (async () => {
      if (lastPathname !== pathname) {
        lastPathnameRef.current = pathname;
        const categoriesRoutes = getCategoriesRoutes() || (await loadData());
        const isValid = isCategoryRoute(pathname, categoriesRoutes);
        setIsValidCategory(isValid);
      }
    })();
  }, [pathname, lastPathname]);

  return isValidCategory;
};
